<template>
  <vx-card>
      <form-wizard v-if="numbers.length > 0" color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-danger), 1)" :title="null" :subtitle="null" finishButtonText="Submit">
          <tab-content :title="$i18n.t('LetterNumber')" class="mb-5" icon="feather icon-file-text" :before-change="validateStep1">

            <!-- tab 1 content -->
            <form data-vv-scope="step-1">
              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-5">
                  <small class="mb-1">{{ $i18n.t('TypeOfLetter') }}</small>
                  <v-select :disabled="id" v-model="form.code" name="jenis_surat" :reduce="item => item.value" :clearable="false" :options="numbers" label="text" v-validate="'required'"></v-select>
                  <!--            <vs-select v-model="form.code" name="code" class="w-full select-large" :label="$i18n.t('TypeOfLetter')" v-validate="'required'">-->
                  <!--              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbers" class="w-full" />-->
                  <!--            </vs-select>-->
                  <span class="text-danger">{{ errors.first('step-1.jenis_surat') }}</span>
                </div>

                <div class="vx-col md:w-3/4 w-full mt-5">
                  <vs-input :label="$i18n.t('LetterNumber')" name="nomor_surat" :value="letter_number_prev" readonly class="w-full" v-validate="'required'" />
                  <span class="text-danger">{{ errors.first('step-1.nomor_surat') }}</span>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-5">
                  <label class="vs-input--label">{{$i18n.t('Date')}}</label>
                  <datepicker format="d/MM/yyyy" name="tanggal" :value="form.tanggal" @input="form.tanggal = $moment($event)" v-validate="'required'"></datepicker>
<!--                  <vs-input type="date" :label="$i18n.t('Date')" v-model="form.tanggal" name="tanggal" class="w-full" v-validate="'required'" />-->
                  <span class="text-danger">{{ errors.first('step-1.tanggal') }}</span>
                </div>

                <div class="vx-col md:w-3/4 w-full mt-5">
                  <vs-input :label="$i18n.t('Subject')" v-model="form.perihal" name="perihal" class="w-full" v-validate="'required'" />
                  <span class="text-danger">{{ errors.first('step-1.perihal') }}</span>
                </div>

                <div class="vx-col md:w-1/4 w-full mt-5">
                  <label class="vs-input--label">{{$i18n.t('PODate')}}</label>
                  <datepicker format="d/MM/yyyy" :value="form.tanggal_po" @input="form.tanggal_po = $moment($event)"></datepicker>
<!--                  <vs-input type="date" :label="$i18n.t('PODate')" v-model="form.tanggal_po" name="tanggal_po" class="w-full" v-validate="'required'" />-->
                  <span class="text-danger">{{ errors.first('step-1.tanggal_po') }}</span>
                </div>
                <div class="vx-col md:w-3/4 w-full mt-5">
                  <vs-input :label="$i18n.t('PONumber')" v-model="form.nomor_po" name="nomor_po" class="w-full" v-validate="'required'" />
                  <span class="text-danger">{{ errors.first('step-1.nomor_po') }}</span>
                </div>
              </div>
            </form>
          </tab-content>

          <!-- tab 2 content -->
          <tab-content :title="$i18n.t('LetterDetail')" class="mb-5" icon="feather icon-briefcase" :before-change="validateStep2">
            <form data-vv-scope="step-2">
              <div class="vx-row">
                <div :class="['vx-col', this.$route.params.id ? 'md:w-3/12' : 'md:w-4/12', 'mt-5']" v-show="this.$route.params.id">
<!--                  <vs-select-->
<!--                    :label="$i18n.t('LetterMaker')"-->
<!--                    :clearable="false" name="pembuat_surat"-->
<!--                    v-model="form.id_user"-->
<!--                    class="w-full mt-4"-->
<!--                  >-->
<!--                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in employees" />-->
<!--                  </vs-select>-->
                  <small class="mb-1">{{ $i18n.t('LetterMaker') }}</small>
                  <v-select :clearable="false" v-model="form.pembuat_surat" :options="employees" label="name"></v-select>
                  <span class="text-danger">{{ errors.first('step-2.pembuat_surat') }}</span>
                </div>
                <div :class="['vx-col', this.$route.params.id ? 'md:w-3/12' : 'md:w-4/12']">
                  <vs-input :label="$i18n.t('To')" name="untuk" v-model="form.untuk" class="w-full mt-4" v-validate="'required'" />
                  <span class="text-danger">{{ errors.first('step-2.untuk') }}</span>
                </div>
                <div :class="['vx-col', this.$route.params.id ? 'md:w-3/12' : 'md:w-4/12']">
                  <vs-input :label="$i18n.t('Up')" name="up" v-model="form.up" class="w-full mt-4" v-validate="'required'" />
                  <span class="text-danger">{{ errors.first('step-2.up') }}</span>
                </div>
                <div :class="['vx-col', this.$route.params.id ? 'md:w-3/12' : 'md:w-4/12']">
                  <vs-input :label="$i18n.t('From')" name="dari" v-model="form.dari" class="w-full mt-4" v-validate="'required'" />
                  <span class="text-danger">{{ errors.first('step-2.dari') }}</span>
                </div>
                <div class="vx-col md:w-3/3 w-full mt-4">
                  <label for="" class="vs-input--label">{{$i18n.t('Description')}}</label>
                  <vs-textarea rows="8" name="keterangan" v-model="form.keterangan" class="w-full" v-validate="'required'" />
                  <span class="text-danger">{{ errors.first('step-2.keterangan') }}</span>
                </div>
                <div class="vx-col md:w-3/3 w-full">
                  <vs-input :label="$i18n.t('FileLocation')" name="lokasi_file" v-model="form.lokasi_file" class="w-full" v-validate="'required'" />
                  <span class="text-danger">{{ errors.first('step-2.lokasi_file') }}</span>
                </div>
              </div>
            </form>
          </tab-content>

          <!-- tab 3 content -->
          <tab-content :title="$i18n.t('Attachment')" class="mb-5" icon="feather icon-paperclip" :before-change="validateStep3">
            <form data-vv-scope="step-3">
              <div class="vx-row">

                <div class="vx-col md:w-1/1 w-full">
                  <vs-input :label="$i18n.t('NoteReferTo')" name="note_refer_to" v-model="form.note_refer_to" class="w-full" v-validate="'required'" />
                  <span class="text-danger">{{ errors.first('step-3.note_refer_to') }}</span>
                </div>

                <div class="vx-col md:w-1/3 w-full">
                  <vs-input :label="$i18n.t('Attachment')" type="file" @change="handleFileChange($event.target.name, $event.target.files)" name="file" class="w-full mt-4" />
                  <span class="text-danger">{{ errors.first('step-3.file') }}</span>
                </div>

              </div>
            </form>
          </tab-content>
      </form-wizard>
  </vx-card>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {mapGetters} from 'vuex'
import axios from '@/axios';

// For custom error message
import { Validator } from 'vee-validate';

export default {
  data() {
    return {
      id: false,
      lastNumber: false,
      form: {
        code: '',
        id_user: this.$store.state.AppActiveUser.nik,
        pembuat_surat: {},
      },
      item: false,
      employees: [],
      dict: {
        custom: {
          // Tab 1
          jenis_surat: {
            required: this.$i18n.t('TypeOfLetter') + ' is required',
          },
          perihal: {
            required: this.$i18n.t('Subject') + ' is required',
          },
          tanggal: {
            required: this.$i18n.t('Date') + ' is required',
          },
          nomor_po: {
            required: this.$i18n.t('PONumber') + ' is required',
          },
          nomor_surat: {
            required: this.$i18n.t('LetterNumber') + ' is required',
          },
          tanggal_po: {
            required: this.$i18n.t('PODate') + ' is required',
          },

          // Tab 2
          untuk: {
            required: this.$i18n.t('Purpose') + ' is required',
          },
          up: {
            required: this.$i18n.t('Up') + ' is required',
          },
          dari: {
            required: this.$i18n.t('From') + ' is required',
          },
          keterangan: {
            required: this.$i18n.t('Description') + ' is required',
          },
          lokasi_file: {
            required: this.$i18n.t('FileLocation') + ' is required',
          },

          // Tab 3
          file: {
            required: this.$i18n.t('Attachment') + ' is required',
          },

          note_refer_to:{
            required: this.$i18n.t('NoteReferTo') + ' is required',
          },
        },
      }
    }
  },
  methods: {
    // on Change
    handleFileChange (event, files) {
      this.form.file = files[0];
    },

    // get all employee
    getEmployees(){

    },

    // append form data
    appendFormData (formData, obj) {
      Object.keys(obj).forEach(key => {
        formData.append(key, obj[key]);
      });

      return formData;
    },

    submit () {
      let payload = { body: new FormData() }, action;

      if (this.id) {
        if (this.form.pembuat_surat) {
          this.form.id_user = this.form.pembuat_surat.nik;
          delete this.form.pembuat_surat;
        } else {
          console.log('tidak memilih pembuat surat');
        }
      }

      this.form.year = this.$route.params.year;

      payload.body = this.appendFormData(payload.body, this.form);

      if (this.id) {
        payload.body.append('_method', 'PUT');
        payload.id = this.id;

        action = this.$store.dispatch("outgoingLetter/updateItem", payload);
      } else {
        action = this.$store.dispatch("outgoingLetter/storeItem", payload);
      }

      action.then((response) => {
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          this.$router.push({name: 'outgoing-letter'});
          console.clear();
        });
      }).catch(err => {

        console.log(err);
        return;

        // set error
        if (err.data.errors) {
          this.error = err.data.errors;
        }

        // show message error
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })

      });
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-1').then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validateStep2() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-2").then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validateStep3() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-3").then(result => {
          if (result) {
            this.submit();
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
  },
  components: {
    FormWizard,
    TabContent,
    Datepicker
  },
  computed: {
    ...mapGetters({
      letter_codes: 'letterCode/getItems',
    }),
    letter_number_prev () {
      if (this.id) {
        if (this.item.number) {
          return `${this.item.number.number}/${this.form.code}-AST/${this.item.number.month}/${this.item.number.year}`;
        }
        return "";
      } else {
        if (this.form.code) {
          let number = this.lastNumber.number + 1;

          if (number.toString().length == 1) {
            number = "00" + number;
          } else if (number.toString().length == 2) {
            number = "0" + number;
          }

          return `${number}/${this.form.code}-AST/${this.lastNumber.month}/${this.lastNumber.year}`;
        }
        return "";
      }
    },
    numbers () {
      let codes = this.letter_codes.map(item => {
        return {
          text: item.name,
          value: item.code
        }
      });
      return codes;
    }
  },
  created() {
    // register custom messages
    Validator.localize('en', this.dict);

    axios.get('/outgoing-letters/last-number?year=' + this.$route.params.year).then(resp => {
      this.lastNumber = resp.data;
    });

    if (this.$route.params.id) {
      this.id = this.$secure.decrypt(this.$route.params.id);

      axios.get(`/employees`)
        .then(({data}) => {
          this.employees = data.values.map(val => {
            return { name: val.name, nik: val.nik };
          });

          axios.get(`/outgoing-letters/${this.id}`).then(response => {
            this.item = response.data.values;
            this.form = response.data.values;
            this.form.code = response.data.values.number.code;

            this.form.pembuat_surat = this.employees.find(emp => emp.nik == this.form.id_user);
          });

        })
        .catch(err => {
          this.$vs.notify({
            title: 'Oops something error',
            text: err.data.message,
            color: 'danger'
          })
        })

    } else {
      this.$store.commit('outgoingLetter/CLEAR_ITEM');
    }

    this.$store.dispatch("letterCode/fetchItems");


  }
}
</script>

<style>
.con-input-upload {
  box-sizing: border-box;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.con-upload .con-img-upload {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>

